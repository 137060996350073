<template>
  <transition name="fade">
    <r-bottom-sheet
      ref="settings"
      :overlay="false"
      class="settings-card"
      initial-height="93%"
      title="Настройки"
      close-button
      @closed="close"
    >
      <r-settings-list :settings="settings" />
    </r-bottom-sheet>
  </transition>
</template>

<script>
export default {
  data () {
    return {
      settings: [
        { id: 1, title: 'Цветовая тема интерфейса', component: 'themeToggler' },
        { id: 2, title: 'Подложка карты', component: 'baseLayerToggler' }
      ]
    }
  },
  mounted () {
    this.$refs.settings.open()
  },
  methods: {
    close () {
      this.$store.commit('RESET_STATE')
      this.$router.push('/map')
    }
  }
}
</script>

<style lang="scss">
.settings-card {
  .r-bottom-sheet__content {
    padding: 0 1rem 0.5rem 1rem !important;
  }
}
</style>
